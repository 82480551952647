@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Roboto+Slab:400,600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
*:focus {
  outline: none;
}

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.4;
}
@media (min-width: 800px) {
  html, body {
    font-size: 12px;
  }
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img {
  max-width: 100%;
  width: 100%;
  display: block;
  margin: 2rem 0;
}

#mboxImported-default-target-global-mbox-0 {
  display: none !important;
}

@font-face {
  font-family: "value";
  src: url("../fonts/value/Value-Regular.woff2") format("woff2"), url("../fonts/value/Value-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "value";
  src: url("../fonts/value/Value-Bold.woff2") format("woff2"), url("../fonts/value/Value-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd.woff2") format("woff2"), url("../fonts/univers/UniversLTStd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "univers";
  src: url("../fonts/univers/UniversLTStd-Bold.woff2") format("woff2"), url("../fonts/univers/UniversLTStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
* {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
}

h1, h2 {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.3;
}

p, a {
  font-size: 1.6rem;
  line-height: 1.4;
}

a {
  color: black;
  font-weight: 600;
  cursor: pointer;
}

em {
  font-family: Roboto, sans-serif;
}

strong {
  font-size: inherit;
  font-weight: 600;
}

.legal {
  font-size: 1.3rem;
}

nav {
  padding: 1.5rem 2rem;
  border-bottom: 2px solid #C5003E;
}
nav a {
  display: block;
  margin: 0;
  padding: 0;
}
nav img {
  width: 210px;
  display: block;
}

footer {
  padding: 1.5rem 2rem;
  border-top: 2px solid #C5003E;
  text-align: center;
}
footer a {
  color: #C5003E;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 400;
  display: inline-block;
  margin: 0 2rem;
}

.cookie-banner-close button {
  padding: 0;
  text-align: right;
  font-size: 1.2rem;
  background: transparent;
  border: none;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 22px;
  width: 100%;
  display: block;
}
@media screen and (min-width: 800px) {
  .cookie-banner-close button {
    width: auto;
  }
}
.cookie-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
.cookie-buttons .cookie-banner-close {
  margin-left: 1rem;
}
.cookie-buttons .cookie-banner-close button {
  cursor: pointer;
}
.cookie-buttons .cookie-banner-close.consent button {
  cursor: pointer;
  background: white;
  color: #707070;
}

.cookie-banner-content p,
.cookie-content, .cookie-banner-content div a {
  font-size: 1.2rem !important;
  color: #ffffff;
  width: 100%;
  display: inline !important;
}
@media screen and (min-width: 1200px) {
  .cookie-banner-content p,
.cookie-content, .cookie-banner-content div a {
    margin-top: 5px;
    margin-bottom: 0;
    display: block;
  }
}

.cookie-content p {
  line-height: 1.5;
}
@media (max-width: 600px) {
  .cookie-content {
    max-width: 100%;
  }
}

.cookie-banner-wrapper {
  max-width: 1200px;
  padding: 0 2rem;
}

.cookie-banner.active {
  z-index: 88888;
  display: block;
}

.cookie-banner-buttons {
  display: flex;
}

.cookie-banner-content {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .cookie-banner-content {
    flex-direction: column;
  }
}

.cookie-banner-content div a {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
}

.cookie-banner-close {
  display: inline;
  font-size: 1.2rem;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}
@media (min-width: 800px) {
  .cookie-banner-close {
    text-decoration: none;
  }
}

.cookie-banner-details {
  line-height: 0 !important;
}

.cookie-banner {
  display: none;
  z-index: 9999;
  background: #444444;
  border-top: 1px solid #ffffff;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
}
@media (min-width: 800px) {
  .cookie-banner {
    transform: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  }
}

.cookie-banner-wrapper {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

form {
  max-width: 100%;
  overflow-x: hidden;
}
form select {
  width: 100%;
  padding: 1rem;
  background: white;
  border-radius: 10rem;
  border: 2px solid #DEDEDE;
  text-align: center;
  color: #010101;
}
form .form__header {
  margin-bottom: 2rem;
  text-align: center;
}
form .form__header .form--heading {
  color: #C5003E;
  margin-bottom: 0;
}
form .form__header h4 {
  color: #C5003E;
  font-size: 1.6rem;
  font-weight: 600;
}
form .form__header h4 span {
  font: inherit;
  color: inherit;
}
form .form__content .question {
  display: none;
}
form .form__content .question.active {
  display: block;
}
form .form__content .showstopper {
  display: none;
}
form .form__content .showstopper.active {
  display: block;
}
form .form__content strong {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.2;
}
form .form__content * {
  font-size: 1.6rem;
}
form .form__content .btn-outline {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  color: #C5003E;
  text-align: center;
  border: 2px solid #DEDEDE;
  border-radius: 10rem;
  transition: all 200ms ease;
  cursor: pointer;
  font-size: 1.8rem;
}
form .form__content .btn-outline:hover {
  border-color: #C5003E;
}
form .form__content input[type=radio] {
  display: none;
}
form .form__content input[type=radio]:checked ~ .btn-outline {
  border-color: #C5003E;
}
form .form__content input[type=text], form .form__content input[type=number], form .form__content input[type=email] {
  border: 2px solid #DEDEDE;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3rem;
  font-size: 1.8rem;
  text-align: center;
}
form .form__content .main__button {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
form .form__content .main__button .large {
  min-width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
}
form .form__content .main__button img {
  margin-left: 1rem;
}
form .form__content .inputGroup {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
form .form__content .inputGroup .toggle {
  margin-right: 1rem;
}
form .form__content .inputGroup #check, form .form__content .inputGroup #checksession {
  display: none;
}
form .form__content .inputGroup #check:checked ~ label .toggle:after, form .form__content .inputGroup #checksession:checked ~ label .toggle:after {
  left: 2.2rem;
  background: #C5003E;
}
form .form__content .inputGroup.success input {
  border-color: #71AE73;
  color: #71AE73;
}
form .form__content .inputGroup.success:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: calc(100% - 4px);
  border-radius: 0 3rem 3rem 0;
  background: url("../img/icn-checkmark-success.svg") center no-repeat;
  background-size: 2rem;
  background-color: white;
  border: 2px solid #71AE73;
  border-left: none;
}
form .form__content .toggle_check {
  display: flex;
  align-items: center;
}
form .form__content .toggle_check span {
  font-size: 1.4rem;
  line-height: 1.4;
}

.alert {
  font-weight: 700;
  color: #C5003E;
  margin: 1rem 0;
  text-align: center;
  display: block;
  min-width: 100%;
}

#bullets {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 380px;
  margin: 3rem auto 1rem;
  position: relative;
  overflow: hidden;
}
#bullets:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 2px;
  background: #707070;
  z-index: -1;
}
#bullets .bullet {
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #707070;
  position: relative;
  transition: all 200ms ease;
}
#bullets .bullet:before {
  transition: all 200ms ease;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: #707070;
  opacity: 0;
}
#bullets .bullet.activated {
  border: 2px solid #707070;
}
#bullets .bullet.activated:before {
  opacity: 1;
  background: #707070;
  transform: translate(-50%, -50%) scale(1);
}
#bullets .bullet.activated:after {
  background: #707070;
}
#bullets .bullet.activated:not(.disabled) {
  cursor: pointer;
}
#bullets .bullet.active {
  border: 2px solid #C5003E;
}
#bullets .bullet.active:before {
  content: "";
  opacity: 1;
  background: #C5003E;
  transform: translate(-50%, -50%) scale(1);
}
#bullets .bullet.active:after {
  content: "";
  background: #C5003E;
  position: absolute;
  z-index: -1;
  height: 2px;
  width: 100vw;
  top: 50%;
  left: 0;
  transform: translate(-100vw, -50%);
}
#bullets .bullet:first-child:after {
  display: none;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#anrede-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;
}
#anrede-wrapper .inputGroup {
  margin: 0;
}
#anrede-wrapper .inputGroup:first-child {
  margin-right: 2rem;
}
#anrede-wrapper .inputGroup label {
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
}
#anrede-wrapper .inputGroup label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid #DEDEDE;
}
#anrede-wrapper .inputGroup input:checked ~ label:before {
  border-color: #C5003E;
  background: radial-gradient(#C5003E 50%, white 55%);
}

.anzeige {
  border: 1px solid #DEDEDE;
  display: block;
  text-decoration: none;
}
.anzeige__header {
  padding: 0.7rem 1rem;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  color: #707070;
  border-bottom: 1px solid #DEDEDE;
}
.anzeige__body .button {
  display: block;
  text-decoration: none;
  background: #C5003E;
  color: white;
  font-size: 2rem;
  padding: 1rem 1rem;
  text-align: center;
  font-family: "value", sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.anzeige__body h3 {
  font-family: "univers", sans-serif;
  font-weight: bold;
  text-align: right;
  font-size: 1.8rem;
  line-height: 1.3;
  max-width: 50%;
  float: right;
}
.anzeige__body-content .cta {
  padding: 0.5rem 1rem;
  font-family: "univers", sans-serif;
  font-size: 1.8rem;
}
.anzeige__body-content-image {
  padding: 2rem;
  width: 100%;
  height: 60vw;
  min-height: 250px;
  max-height: 500px;
}
.anzeige__body-content-image.image_step_1 {
  background: url("../img/Q1_visual_allgemein.jpg") center no-repeat;
  background-size: 220%;
}

#anzeige_multistep .anzeige {
  overflow: hidden;
}
#anzeige_multistep .anzeige__body-content-image {
  transform: translateX(-100vw);
  transition: transform 500ms ease;
}
#anzeige_multistep .anzeige__body-content-image h3 {
  transform: translateX(200vw);
  transition: transform 500ms ease;
}
#anzeige_multistep .anzeige__body-content .button {
  transform: translateY(100%);
  transition: transform 500ms ease;
}
#anzeige_multistep.animated .anzeige__body-content-image {
  transform: translateX(0);
}
#anzeige_multistep.animated .anzeige__body-content-image h3 {
  transform: translateX(0);
}
#anzeige_multistep.animated .anzeige__body-content .button {
  transform: translateY(0);
}

.cta__box {
  padding: 1.5rem;
  background: #f8f8f8;
}
.cta__box h3 {
  line-height: 1.4;
  margin-bottom: 1rem;
}

ul {
  list-style-type: none;
  font-size: 1.6rem;
  margin: 2rem 0;
}
ul li {
  margin-left: 3rem;
  position: relative;
  margin-bottom: 1rem;
}
ul li:before {
  content: "";
  width: 23px;
  height: 26px;
  background: url("../img/icn-check.svg") center no-repeat;
  background-size: contain;
  position: absolute;
  left: -3rem;
  top: -4px;
}
ul.steps li {
  margin-left: 5rem;
  margin-bottom: 5rem;
}
ul.steps li:before {
  border: 5px solid white;
  content: "1";
  position: absolute;
  left: -5rem;
  top: 50%;
  transform: translateY(-50%);
  background: #707070;
  font-family: "value", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 2.4rem;
  text-align: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  line-height: 3.6rem;
  display: block;
}
ul.steps li:after {
  content: "";
  position: absolute;
  left: -2.8rem;
  top: 100%;
  height: 5rem;
  width: 3px;
  background: #707070;
  z-index: -1;
}
ul.steps li:first-child:before {
  background: url("../img/icn-checkmark.svg"), #C5003E;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
}
ul.steps li:nth-child(3):before {
  content: "2";
}
ul.steps li:nth-child(4):before {
  content: "3";
}
ul.steps li:nth-child(4):after {
  display: none;
}
ul.arrow--list li {
  padding-left: 2.5rem;
  margin-left: 0;
  margin-bottom: 2rem;
  font-weight: bold;
}
ul.arrow--list li:before {
  width: 1rem;
  height: 1.5rem;
  background: url("../img/icn-arrow-r.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  content: "";
  top: 1px;
  left: 0;
}

.phone__icon {
  margin-right: 1rem;
  position: relative;
  top: 0;
  width: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}

.download__icon {
  position: relative;
  margin-right: 1rem;
  top: 3px;
  width: 3rem;
}

.main__button {
  display: block;
  text-decoration: none;
  border-radius: 10rem;
  background: #C5003E;
  color: white;
  font-family: "value", sans-serif;
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease;
}
.main__button:after {
  transition: inherit;
  content: "";
  width: 0;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main__button:hover:after {
  width: 100vw;
  height: 100vw;
}

.main__button_content {
  display: inline-flex;
  text-decoration: none;
  border-radius: 10rem;
  background: #C5003E;
  color: white;
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease;
}
.main__button_content:after {
  transition: inherit;
  content: "";
  width: 0;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main__button_content:hover:after {
  width: 100vw;
  height: 100vw;
}

.toggle {
  width: 5rem;
  min-width: 5rem;
  height: 3rem;
  border: 2px solid #DEDEDE;
  border-radius: 50px;
  position: relative;
  display: block;
  cursor: pointer;
}
.toggle:after {
  content: "";
  position: absolute;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  background: #DEDEDE;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: all 150ms ease;
}
.toggle.active:after {
  left: 2.2rem;
  background: #C5003E;
}

.icon__next {
  position: relative;
  margin-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  width: 1rem;
}

#glueckliche_kunden {
  background: #C5003E;
  color: white;
  padding: 3rem 2rem;
}
#glueckliche_kunden h3 {
  width: 100%;
  text-align: center;
  max-width: 80rem;
  display: block;
  margin: 0 auto;
  font-size: 2rem;
}

#loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  text-align: left;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
#loader.loading {
  display: flex;
}
#loader.loaded {
  display: none;
}
#loader .found {
  display: none;
  text-align: center;
}
@media (min-width: 800px) {
  #loader {
    z-index: 100;
  }
  #loader .loader__content {
    width: 500px;
    background: #fff;
    padding: 3rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  #loader .loader__content .kunden_overlay {
    margin: 2rem auto 0;
    display: block;
  }
  #loader .loader__content .loader_logo {
    width: 220px;
    margin: 0 auto 2rem;
  }
  #loader .loader__content #close_button {
    font-weight: 400;
  }
  #loader .loader__content .loader {
    text-align: center;
  }
  #loader .loader__content .list {
    width: 100%;
  }
  #loader .loader__content h2 {
    font-size: 2rem;
    max-width: 400px;
  }
  #loader .loader__content .found {
    display: none;
  }
  #loader .loader__content .found #close_button {
    text-decoration: underline;
    margin: 2rem 0;
    display: block;
    cursor: pointer;
  }
  #loader .loader__content .found.active {
    display: block;
  }
  #loader .loader__content .found.active .main__button {
    margin: 2rem 0;
  }
  #loader .loader__content .found.active h3 {
    color: #71AE73;
  }
  #loader .loader__content .found.active svg {
    width: 50px;
    height: 50px;
  }
  @supports not (-ms-high-contrast: none) {
    #loader .loader__content .found.active svg #kreis {
      animation: draw 1s forwards ease;
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
    }
    #loader .loader__content .found.active svg #check1, #loader .loader__content .found.active svg #check2 {
      animation: draw 1s forwards ease;
      stroke-dasharray: 200;
      stroke-dashoffset: 200;
    }
    #loader .loader__content .found.active svg #check1 {
      animation-delay: 500ms;
    }
    #loader .loader__content .found.active svg #check2 {
      animation-delay: 600ms;
    }
  }
}

#tester {
  text-align: center;
  margin-bottom: 2rem;
}
#tester h3 {
  font-family: "value", sans-serif;
}
#tester .checkbox svg {
  width: 5rem;
}

.spinner__loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(197, 0, 62, 0.2);
  border-radius: 50%;
  margin: 2rem auto;
  position: relative;
  animation: rotate 1s linear infinite forwards;
}
.spinner__loader:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #C5003E;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes draw {
  0% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.testimonial {
  display: flex;
  align-items: center;
}
.testimonial img {
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;
}
.testimonial .testimonial--info * {
  font-family: "univers", sans-serif;
  font-size: 1.2rem;
  color: #707070;
}

#version--redaktionell {
  background: white;
}
#version--redaktionell *:not(.sansserif) {
  font-family: "Roboto Slab", serif;
}
#version--redaktionell h1, #version--redaktionell h2 {
  font-size: 2.2rem;
  color: #010101;
}
@media (min-width: 800px) {
  #version--redaktionell h1, #version--redaktionell h2 {
    font-size: 4rem;
  }
}
#version--redaktionell header {
  background: white;
  width: 100%;
  border-bottom: 1px solid #C5003E;
}
#version--redaktionell header nav {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}
#version--redaktionell header .header--hinweis {
  background: #C5003E;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
}
#version--redaktionell header .header--hinweis * {
  color: white;
}
#version--redaktionell header .header--hinweis a {
  text-decoration: none;
}
#version--redaktionell .main--section {
  width: 100%;
  max-width: 80rem;
  padding: 2rem;
  margin: 0 auto;
}
#version--redaktionell .main--section small {
  font-size: 1.2rem;
  font-family: univers, sans-serif;
}
#version--redaktionell .main--section hr {
  border: none;
  width: 10rem;
  background: #707070;
  height: 1px;
  margin: 1rem 0;
}
#version--redaktionell .main--section .testimonial {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}
#version--redaktionell .main--section .testimonial img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}
#version--redaktionell .main--section .testimonial .testimonial--info strong, #version--redaktionell .main--section .testimonial .testimonial--info p {
  font-size: 1.2rem;
  color: #707070;
  margin: 0;
}
#version--redaktionell .main--section .kk-bild {
  background-size: cover;
  width: 100%;
  height: 50vw;
  max-height: 40rem;
  cursor: pointer;
  margin: 2rem 0;
  background-position: center;
}
#version--redaktionell .main--section .krankenkassenbild {
  background-image: url("/assets/img/krankenkasse/header.jpg");
}
#version--redaktionell .main--section .hoerenbild {
  background-image: url("/assets/img/krankenkasse/hoeren.jpg");
  margin-top: 2rem;
}
#version--redaktionell .main--section .hoergeraetebild {
  background-image: url("/assets/img/krankenkasse/hoergeraet.jpg");
  background-position: top center;
}
#version--redaktionell .infobox {
  border: 1px solid #707070;
}
#version--redaktionell .infobox .top--content {
  padding: 2rem;
  background: #DEDEDE;
}
#version--redaktionell .infobox .top--content .checklist li {
  font-weight: normal;
}
#version--redaktionell .infobox .bottom--content {
  background: #C5003E;
  padding: 2rem;
}
#version--redaktionell .infobox .bottom--content * {
  color: white;
}
#version--redaktionell .infobox .bottom--content .main__button {
  border: 1px solid white;
  margin-top: 1rem;
}
#version--redaktionell #lightbox .lightbox {
  padding: 1rem;
  max-width: 100%;
}
#version--redaktionell #loader {
  background: white !important;
}
#version--redaktionell #loader h3 {
  text-align: center;
}
#version--redaktionell footer {
  text-align: center;
  padding: 2rem 0;
  border-top: 1px solid #C5003E;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
#version--redaktionell footer .legal--links {
  text-align: center;
  margin: 0 auto;
  display: block;
}
#version--redaktionell footer .legal--links a {
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 540px) {
  #version--redaktionell footer .legal--links {
    display: flex;
  }
}

.kantone {
  margin: 2rem 0;
}
@media (min-width: 540px) {
  .kantone {
    display: flex;
    flex-wrap: wrap;
  }
}
.kantone .radio--group {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  flex: 1 0 45%;
}
.kantone .radio--group label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border: 1px solid #707070;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
  color: #C5003E;
  transition: all 300ms ease;
  height: 5rem;
  justify-content: center;
  text-align: center;
}
.kantone .radio--group label .label__content {
  display: flex;
  align-items: center;
  width: 13rem;
}
.kantone .radio--group label .kk--icon {
  width: 3rem;
  height: 3.6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 1rem;
}
.kantone .radio--group label.label-noicon {
  text-align: center;
  justify-content: center;
  height: 5rem;
  background: #C5003E;
  color: white;
  border-color: #C5003E;
}
.kantone .radio--group label:hover {
  background: #DEDEDE;
  color: #C5003E;
}
.kantone .radio--group input {
  display: none;
}
.kantone .radio--group input:checked ~ label {
  background: white !important;
  color: #C5003E !important;
  border-color: #C5003E !important;
}
.kantone .radio--group:nth-child(odd) {
  margin-right: 1rem;
}
.kantone .radio--group:last-child {
  margin-right: 0;
}

.zertifikate {
  margin-bottom: 4rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-areas: "s1 s2 s3";
  grid-auto-columns: auto auto auto;
  grid-template-rows: auto;
  justify-content: center;
}
.zertifikate .zertifikat:nth-child(1) {
  grid-area: s1;
}
.zertifikate .zertifikat:nth-child(2) {
  grid-area: s2;
}
.zertifikate .zertifikat:nth-child(3) {
  grid-area: s3;
}
.zertifikate .zertifikat img {
  height: 15vw;
  max-height: 12rem;
  min-height: 7rem;
  width: auto;
  margin: 0;
}

.content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.nav__content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
@media (min-width: 800px) {
  .nav__content {
    padding: 0 2rem;
  }
}

.row__image {
  margin-bottom: 2rem;
}

.date {
  font-size: 1.3rem;
  font-family: Roboto, sans-serif;
  color: #707070;
  font-style: normal;
  display: block;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
}
.date:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 150px;
  height: 1px;
  background: #707070;
}
.date strong {
  font-weight: 700;
  font-family: inherit;
}

.grey {
  background: #f8f8f8;
}

.desktop {
  display: none !important;
}
@media (min-width: 800px) {
  .desktop {
    display: block !important;
  }
}

@media (min-width: 800px) {
  .mobile {
    display: none !important;
  }
}

#danke h1 {
  color: #C5003E;
  position: relative;
  margin-left: 4.5rem;
}
#danke h1:before {
  content: "";
  position: absolute;
  left: -4.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: url("../img/icn-checkmark.svg") center no-repeat, #C5003E;
  background-size: 2.3rem;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  display: block;
}
#danke hr {
  width: 100%;
  border: none;
  height: 1px;
  background: #DEDEDE;
  margin: 1rem 0 2rem;
}
#danke .buchungsnummer {
  color: #C5003E;
}
#danke h2 {
  color: black;
  pointer-events: none;
  text-decoration: none;
}
@media (min-width: 800px) {
  #danke {
    padding: 4rem;
    display: flex;
    align-items: flex-start;
  }
  #danke hr {
    display: none;
  }
  #danke .col-1 {
    flex: 2 0 0;
    padding-right: 3rem;
  }
  #danke .col-1 h2 {
    font-size: 3rem;
  }
  #danke .col-1 h2 .phone__icon {
    width: 3rem;
    height: 3rem;
    display: inline-block;
    margin: 0;
    margin-right: 1rem;
  }
  #danke .col-2 {
    padding: 2rem;
    border: 1px solid #C5003E;
    background: #fff;
  }
  #danke .col-2 h2 {
    font-size: 2rem;
  }
  #danke .main__button_content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  #danke .main__button_content img {
    margin: 0 1rem 0 0;
    width: 2rem;
    top: 0;
  }
}

#schritte h2 {
  color: black;
  margin-bottom: 0;
}
@media (min-width: 800px) {
  #schritte {
    padding: 4rem;
  }
}