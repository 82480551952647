*
	margin: 0
	padding: 0
	box-sizing: border-box
	-webkit-font-smoothing: antialiased
	moz-osx-font-smoothing: grayscale
	scroll-behavior: smooth
	&:focus
		outline: none



html, body
	height: 100%
	font-size: 10px
	line-height: 1.4
	// transition: all 1s ease
	@media (min-width: 800px)
		font-size: 12px

body
	display: flex
	flex-direction: column
	justify-content: space-between

img
	max-width: 100%
	width: 100%
	display: block
	margin: 2rem 0


#mboxImported-default-target-global-mbox-0
	display: none!important





