.zertifikate
	margin-bottom: 4rem
	display: grid
	grid-gap: 2rem
	grid-template-areas: 's1 s2 s3'
	grid-auto-columns: auto auto auto
	grid-template-rows: auto
	justify-content: center
	.zertifikat
		@for $i from 1 through 3
			&:nth-child(#{$i})
				grid-area: s#{$i}
		img
			height: 15vw
			max-height: 12rem
			min-height: 7rem
			width: auto
			margin: 0