
.cookie-banner-close button
	padding: 0
	text-align: right
	font-size: 1.2rem
	background: transparent
	border: none
	border: 2px solid #ffffff
	color: #ffffff
	padding: 10px 20px
	border-radius: 22px
	width: 100%
	display: block
	// margin-top: 1rem
	
	@media screen and (min-width: 800px)
		width: auto
	@media screen and (max-width: 800px)
		// padding: 0
		// border: none
		// text-align: right
		// text-decoration: underline
		// font-size: 1.2rem
		// margin-left: auto
.cookie-buttons
	display: flex
	align-items: center
	justify-content: flex-end
	margin-top: 1rem
	.cookie-banner-close
		margin-left: 1rem
		
		button
			cursor: pointer
		&.consent
			button
				cursor: pointer
				background: white
				color: $grau


.cookie-banner-content p,
.cookie-content, .cookie-banner-content div a 
	font-size: 1.2rem!important
	//margin-bottom: 5px
	color: #ffffff
	width: 100%
	//float: left
	//max-width: 70%
	display: inline!important
	@media screen and (min-width: $max-width)
		//max-width: 80%
		//margin-right: 95px
		margin-top: 5px
		margin-bottom: 0
		display: block

.cookie-content
	//margin-left: auto
	p
		line-height: 1.5
	@media (max-width: 600px)
		max-width: 100%
		//margin-bottom: 10px

.cookie-banner-wrapper 
	max-width: $max-width
	padding: 0 2rem
	
	
.cookie-banner.active
	z-index: 88888
	display: block
	

.cookie-banner-buttons 
	display: flex
	

.cookie-banner-content 
	margin: 0 auto
	display: flex
	justify-content: space-between
	@media (max-width: 600px)
		flex-direction: column
	

.cookie-banner-content div a 
	font-size: 1rem
	font-weight: bold
	display: inline-block
	

.cookie-banner-close 
	display: inline
	font-size: 1.2rem
	text-decoration: underline
	color: #000
	cursor: pointer
	@media (min-width: 800px)
		text-decoration: none
	
.cookie-banner-details 
	line-height: 0!important
	

.cookie-banner 
	display: none
	z-index: 9999
	background: #444444
	border-top: 1px solid #ffffff
	position: fixed
	width: 100%
	// max-width: 1200px
	bottom: 0
	padding: 1rem 0
	// left: 50%
	// transform: translateX(-50%)
	@media (min-width: 800px)
		// width: 400px
		// left: auto
		// right: 20px
		transform: none
		// bottom: 20px
		box-shadow: 2px 2px 10px rgba(black, 0.3)
	

.cookie-banner-wrapper 
	// padding: 0 1rem
	margin: 0 auto
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	