@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Roboto+Slab:400,600&display=swap')

@font-face 
	font-family: 'value'
	src: url('../fonts/value/Value-Regular.woff2') format('woff2'), url('../fonts/value/Value-Regular.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'value'
	src: url('../fonts/value/Value-Bold.woff2') format('woff2'), url('../fonts/value/Value-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd.woff2') format('woff2'), url('../fonts/univers/UniversLTStd.woff') format('woff')
	font-weight: normal
	font-style: normal


@font-face 
	font-family: 'univers'
	src: url('../fonts/univers/UniversLTStd-Bold.woff2') format('woff2'), url('../fonts/univers/UniversLTStd-Bold.woff') format('woff')
	font-weight: bold
	font-style: normal

*
	font-family: $slab
	font-weight: 400

h1, h2
	font-size: 2.2rem	 
	font-weight: 600
	line-height: 1.3
	margin-bottom: 2rem

h3
	font-size: 1.6rem
	font-weight: 600
	line-height: 1.3
p, a
	font-size: 1.6rem
	line-height: 1.4



a
	color: black
	font-weight: 600
	cursor: pointer

em
	font-family: $font

strong
	font-size: inherit
	font-weight: 600

.legal
	font-size: 1.3rem


