.main__button
	display: block
	text-decoration: none
	border-radius: 10rem
	background: $rot
	color: white
	font-family: $value
	font-weight: bold
	text-align: center
	padding: 1rem 2rem
	cursor: pointer
	position: relative
	overflow: hidden
	transition: all 200ms ease
	&:after
		transition: inherit
		content: ""
		width: 0
		height: 100%
		border-radius: 50%
		background: rgba(white, 0.2)
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
	&:hover
		&:after
			width: 100vw
			height: 100vw

.main__button_content
	display: inline-flex
	text-decoration: none
	border-radius: 10rem
	background: $rot
	color: white
	font-family: $slab
	font-weight: bold
	text-align: center
	padding: 1rem 2rem
	cursor: pointer
	position: relative
	overflow: hidden
	transition: all 200ms ease
	&:after
		transition: inherit
		content: ""
		width: 0
		height: 100%
		border-radius: 50%
		background: rgba(white, 0.2)
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
	&:hover
		&:after
			width: 100vw
			height: 100vw
.toggle
	width: 5rem
	min-width: 5rem
	height: 3rem
	border: 2px solid $hellgrau
	border-radius: 50px
	position: relative
	display: block
	cursor: pointer
	&:after
		content: ""
		position: absolute
		height: 2.2rem
		width: 2.2rem
		border-radius: 50%
		background: $hellgrau
		top: 50%
		left: 2px
		transform: translateY(-50%)
		transition: all 150ms ease
	&.active
		&:after
			left: 2.2rem
			background: $rot
.icon__next
	position: relative
	margin-left: 1rem
	margin-top: 0
	margin-bottom: 0
	width: 1rem