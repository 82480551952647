@import 'header'
@import 'footer'
@import 'cookies'
@import 'formular'
@import 'anzeige'
@import 'abbinder_box'
@import 'listen'
@import 'icons'
@import 'buttons'
@import 'kunden'
@import 'loader'
@import 'testimonial'
@import 'kanton'
@import 'zertifikate'
.content
	width: 100%
	max-width: 800px
	margin: 0 auto
	padding: 2rem
// .w1200
//	 max-width: 1000px
.nav__content
	width: 100%
	max-width: 800px
	margin: 0 auto
	@media (min-width: 800px)
		padding: 0 2rem
.row__image
	margin-bottom: 2rem
.date
	font-size: 1.3rem
	font-family: $font
	color: $grau
	font-style: normal
	display: block
	margin-top: 1.5rem
	padding-bottom: 1.5rem
	margin-bottom: 2rem
	position: relative
	&:after
		content: ""
		position: absolute
		left: 0
		bottom: 0
		width: 150px
		height: 1px
		background: $grau
	strong
		font-weight: 700
		font-family: inherit
.grey
	background: lighten($hellgrau, 10%)

.desktop
	display: none!important
	@media(min-width: 800px)
		display: block!important
.mobile
	@media(min-width: 800px)
		display: none!important