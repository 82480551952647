footer
	padding: 1.5rem 2rem
	border-top: 2px solid $rot
	text-align: center
	a
		color: $rot
		font-size: 1.4rem
		text-decoration: none
		font-weight: 400
		display: inline-block
		margin: 0 2rem