#danke
	h1
		color: $rot
		position: relative
		margin-left: 4.5rem
		&:before
			content: ""
			position: absolute
			left: -4.5rem
			top: 50%
			transform: translateY(-50%)
			background: url('../img/icn-checkmark.svg') center no-repeat, $rot
			background-size: 2.3rem
			border-radius: 50%
			width: 3.6rem
			height: 3.6rem
			display: block
	hr
		width: 100%
		border: none
		height: 1px
		background: $hellgrau
		margin: 1rem 0 2rem
	.buchungsnummer
		color: $rot
	h2
		color: black
		pointer-events: none
		text-decoration: none
	@media (min-width: 800px)
		padding: 4rem
		display: flex
		align-items: flex-start
		hr
			display: none
		.col-1
			flex: 2 0 0
			padding-right: 3rem
			h2
				font-size: 3rem
				.phone__icon
					width: 3rem
					height: 3rem
					display: inline-block
					margin: 0
					margin-right: 1rem
		.col-2
			padding: 2rem
			border: 1px solid $rot
			background: #fff
			//margin: 3rem
			// flex: 1 0 auto
			h2
				font-size: 2rem
		.main__button_content
			display: inline-flex
			align-items: center
			justify-content: center
			img
				margin: 0 1rem 0 0
				width: 2rem
				top: 0

#schritte
	h2
		color: black
		margin-bottom: 0
	@media (min-width: 800px)
		padding: 4rem