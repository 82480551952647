ul
	list-style-type: none
	font-size: 1.6rem
	margin: 2rem 0
	li
		margin-left: 3rem
		position: relative
		margin-bottom: 1rem
		&:before
			content: ""
			width: 23px
			height: 26px
			background: url('../img/icn-check.svg') center no-repeat
			background-size: contain
			position: absolute
			left: -3rem
			top: -4px
	&.steps
		li
			margin-left: 5rem
			margin-bottom: 5rem
			&:before
				border: 5px solid white
				content: "1"
				position: absolute
				left: -5rem
				top: 50%
				transform: translateY(-50%)
				background: $grau
				font-family: $value
				font-weight: bold
				color: white
				font-size: 2.4rem
				text-align: center
				width: 3.6rem
				height: 3.6rem
				border-radius: 50%
				line-height: 3.6rem
				display: block
			&:after
				content: ""
				position: absolute
				left: -2.8rem
				top: 100%
				height: 5rem
				width: 3px
				background: $grau
				z-index: -1
			&:first-child
				&:before
					background: url('../img/icn-checkmark.svg'), $rot
					background-size: 60%
					background-repeat: no-repeat
					background-position: center
					content: ""
				
			&:nth-child(3)
				&:before
					content: "2"
			&:nth-child(4)
				&:before
					content: "3"
				&:after
					display: none
	&.arrow--list
		li
			padding-left: 2.5rem
			margin-left: 0
			margin-bottom: 2rem
			font-weight: bold
			&:before
				width: 1rem
				height: 1.5rem
				background: url('../img/icn-arrow-r.svg')
				background-size: contain
				background-repeat: no-repeat
				background-position: left center
				content: ""
				top: 1px
				left: 0
			